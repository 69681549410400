var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "equipment", attrs: { id: "ruleWrapper" } }, [
    _c("div", { staticClass: "e_content bgFFF" }, [
      _c(
        "div",
        { staticClass: "ruleMessage1" },
        [
          _vm._m(0),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.formInline,
                rules: _vm.rules,
                "label-width": "140px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "f-top" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.Coupon_Name"),
                        prop: "couponName",
                        disabled: false,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          type: "text",
                          placeholder: "请输入优惠券名称",
                          "auto-complete": "off",
                          disabled: _vm.isDetail,
                          maxlength: "20",
                        },
                        model: {
                          value: _vm.formInline.couponName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "couponName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formInline.couponName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "模式", prop: "couponMode" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.isDetail },
                          on: { change: _vm.changeCouponMode },
                          model: {
                            value: _vm.formInline.couponMode,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "couponMode", $$v)
                            },
                            expression: "formInline.couponMode",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("自有券"),
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("合作券"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.formInline.couponMode == 0 || _vm.storeList.length <= 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "合作方", prop: "partnerName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              placeholder: "请输入合作方",
                              disabled:
                                _vm.formInline.couponMode == 0 || _vm.isDetail,
                              maxlength: "20",
                            },
                            model: {
                              value: _vm.formInline.partnerName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline,
                                  "partnerName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formInline.partnerName",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "el-form-item",
                        { attrs: { label: "合作方", prop: "storeIds" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "250px" },
                              attrs: {
                                disabled: _vm.isDetail,
                                multiple: "",
                                placeholder: "合作方名称",
                                "remote-method": _vm.remoteMethod,
                                loading: _vm.loading,
                              },
                              model: {
                                value: _vm.formInline.storeIds,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formInline,
                                    "storeIds",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "formInline.storeIds",
                              },
                            },
                            _vm._l(_vm.storeList, function (item) {
                              return _c("el-option", {
                                key: item.storeId,
                                attrs: {
                                  label: item.storeName,
                                  value: item.storeId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "业务类型", prop: "businessType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { disabled: _vm.isDetail },
                          model: {
                            value: _vm.formInline.businessType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "businessType", $$v)
                            },
                            expression: "formInline.businessType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "停车", value: 0 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.Coupon_type"),
                        prop: "couponType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "250px" },
                          attrs: { disabled: _vm.isDetail },
                          model: {
                            value: _vm.formInline.couponType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline,
                                "couponType",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.couponType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "现金券", value: 0 },
                          }),
                          _c("el-option", {
                            attrs: { label: "折扣券", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "全免券", value: 2 },
                          }),
                          _c("el-option", {
                            attrs: { label: "小时券", value: 3 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.formInline.couponType === 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "优惠券面值", prop: "faceValue1" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100px" },
                            attrs: {
                              placeholder: "金额",
                              maxlength: "4",
                              disabled: _vm.isDetail,
                            },
                            model: {
                              value: _vm.formInline.faceValue1,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline,
                                  "faceValue1",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formInline.faceValue1",
                            },
                          }),
                          _c("span", [_vm._v("元")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formInline.couponType === 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "使用条件", prop: "threshold1" } },
                        [
                          _c("span", [_vm._v("满 ")]),
                          _c("el-input", {
                            staticStyle: { width: "80px" },
                            attrs: {
                              placeholder: "金额",
                              maxlength: "4",
                              disabled: _vm.isDetail,
                            },
                            model: {
                              value: _vm.formInline.threshold1,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline,
                                  "threshold1",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formInline.threshold1",
                            },
                          }),
                          _c("span", [_vm._v(" 元及以上使用")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formInline.couponType === 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "打折比例", prop: "faceValue2" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "120px" },
                            attrs: {
                              placeholder: "百分比",
                              maxlength: "4",
                              disabled: _vm.isDetail,
                            },
                            model: {
                              value: _vm.formInline.faceValue2,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline,
                                  "faceValue2",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formInline.faceValue2",
                            },
                          }),
                          _vm._v(" % "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formInline.couponType === 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "折扣金额上限", prop: "threshold2" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "120px" },
                            attrs: {
                              placeholder: "金额",
                              maxlength: "4",
                              disabled: _vm.isDetail,
                            },
                            model: {
                              value: _vm.formInline.threshold2,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline,
                                  "threshold2",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formInline.threshold2",
                            },
                          }),
                          _c("span", { staticStyle: { color: "#ccc" } }, [
                            _vm._v("*无上限设为0"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formInline.couponType === 3
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "优惠券面值", prop: "faceValue3" } },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c(
                                "span",
                                { staticStyle: { "vertical-align": "middle" } },
                                [_vm._v("优惠券面值")]
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content:
                                      "优惠券面值是商家给车主的优惠时长，是车主能享受的最长停车时长优惠",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "help" }, [
                                    _vm._v("?"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("el-input", {
                            staticStyle: { width: "120px" },
                            attrs: {
                              placeholder: "请输入小时",
                              disabled: _vm.isDetail,
                              maxlength: "3",
                            },
                            model: {
                              value: _vm.formInline.faceValue3,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline,
                                  "faceValue3",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formInline.faceValue3",
                            },
                          }),
                          _c("span", [_vm._v(" 小时")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "debtHours", label: "欠费时长不超过" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "80px" },
                        attrs: { disabled: _vm.isDetail, maxlength: "4" },
                        model: {
                          value: _vm.formInline.debtHours,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "debtHours",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formInline.debtHours",
                        },
                      }),
                      _c("span", [_vm._v(" 小时可使用")]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "有效日期", prop: "effectType" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.isDetail },
                          model: {
                            value: _vm.formInline.effectType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "effectType", $$v)
                            },
                            expression: "formInline.effectType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("固定日期"),
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("自领取日起"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _vm.formInline.effectType == 0
                            ? _c(
                                "el-form-item",
                                { attrs: { prop: "effectTime" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      "picker-options": _vm.pickerOptions,
                                      type: "daterange",
                                      "value-format": "yyyy-MM-dd",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      disabled: _vm.isDetail,
                                    },
                                    model: {
                                      value: _vm.formInline.effectTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "effectTime",
                                          $$v
                                        )
                                      },
                                      expression: "formInline.effectTime",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.formInline.effectType == 1
                            ? _c(
                                "el-form-item",
                                { attrs: { prop: "effectDays" } },
                                [
                                  _c("span", [_vm._v("自领取后 ")]),
                                  _c("el-input", {
                                    staticStyle: { width: "60px" },
                                    attrs: {
                                      maxlength: "3",
                                      disabled: _vm.isDetail,
                                    },
                                    model: {
                                      value: _vm.formInline.days,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "days",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formInline.days",
                                    },
                                  }),
                                  _vm._v(" 天 "),
                                  _c("el-input", {
                                    staticStyle: { width: "60px" },
                                    attrs: {
                                      maxlength: "3",
                                      disabled: _vm.isDetail,
                                    },
                                    model: {
                                      value: _vm.formInline.hours,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "hours",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formInline.hours",
                                    },
                                  }),
                                  _vm._v(" 时 "),
                                  _c("el-input", {
                                    staticStyle: { width: "60px" },
                                    attrs: {
                                      maxlength: "3",
                                      disabled: _vm.isDetail,
                                    },
                                    model: {
                                      value: _vm.formInline.minutes,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "minutes",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formInline.minutes",
                                    },
                                  }),
                                  _c("span", [_vm._v(" 分内有效")]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "可用终端", prop: "useClient" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          attrs: { disabled: _vm.isDetail },
                          model: {
                            value: _vm.formInline.useClient,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "useClient", $$v)
                            },
                            expression: "formInline.useClient",
                          },
                        },
                        [
                          _c("el-checkbox", { attrs: { label: "1" } }, [
                            _vm._v("APP"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "2" } }, [
                            _vm._v("微信公众号"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "3" } }, [
                            _vm._v("微信小程序"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "4" } }, [
                            _vm._v("支付宝生活号"),
                          ]),
                          _c("el-checkbox", { attrs: { label: "5" } }, [
                            _vm._v("支付宝小程序"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "使用范围", prop: "scope" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "250px" },
                          attrs: { disabled: _vm.isDetail },
                          model: {
                            value: _vm.formInline.scope,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline,
                                "scope",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.scope",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "按道路/车场", value: 2 },
                          }),
                          _c("el-option", {
                            attrs: { label: "按商户", value: 3 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.formInline.scope === 3
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("searchModule.Merchant"),
                            prop: "merchantValue",
                          },
                        },
                        [
                          _c("transfer", {
                            attrs: {
                              searchUrl: "/acb/2.0/operation/nameList",
                              params: "likeOperationName",
                              placeholder: "请输入商户名称",
                              value: _vm.formInline.merchantValue,
                              isDetail: _vm.isDetail,
                              checkItemArr: _vm.merchantCheckItemArr,
                              anotherName: {
                                key: "operationId",
                                label: "operationName",
                              },
                            },
                            on: { setSelectVal: _vm.setMerchantValue },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formInline.scope === 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("searchModule.Road_or_Parking_Lot"),
                            prop: "yardValue",
                          },
                        },
                        [
                          _c("transfer", {
                            attrs: {
                              checkItemArr: _vm.yardCheckItemArr,
                              isDetail: _vm.isDetail,
                              value: _vm.formInline.yardValue,
                            },
                            on: { setSelectVal: _vm.setParkValue },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formInline.couponMode == 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "结算模式", prop: "settlementMoney" },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c(
                                "span",
                                { staticStyle: { "vertical-align": "middle" } },
                                [_vm._v("结算模式")]
                              ),
                              _c(
                                "el-tooltip",
                                { attrs: { placement: "top" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "结算模式是跟商家进行结算的费用方式："
                                        ),
                                      ]),
                                      _c("br"),
                                      _c("span", [
                                        _vm._v(
                                          "固定金额：每成功减免一次按固定金额与商家结算；"
                                        ),
                                      ]),
                                      _c("br"),
                                      _c("span", [
                                        _vm._v(
                                          "实际金额：每成功减免一次按车主在这次减免中实际优惠的金额与商家结算；结算费用未超过上限费用的按实际金额结算，超过的按上限费用结算；"
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("span", { staticClass: "help" }, [
                                    _vm._v("?"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.isDetail },
                              on: { change: _vm.changeMode },
                              model: {
                                value: _vm.formInline.settlementMode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formInline,
                                    "settlementMode",
                                    $$v
                                  )
                                },
                                expression: "formInline.settlementMode",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("固定金额"),
                              ]),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("实际金额"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _vm.formInline.settlementMode == 1
                                ? _c("el-input", {
                                    staticStyle: { width: "250px" },
                                    attrs: { disabled: _vm.isDetail },
                                    model: {
                                      value: _vm.formInline.settlementMoney,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "settlementMoney",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formInline.settlementMoney",
                                    },
                                  })
                                : _vm._e(),
                              _vm.formInline.settlementMode == 2
                                ? _c("el-input", {
                                    staticStyle: { width: "250px" },
                                    attrs: {
                                      disabled: _vm.isDetail,
                                      placeholder:
                                        "请输入结算上限，请看左侧说明",
                                    },
                                    model: {
                                      value: _vm.formInline.settlementMoney,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "settlementMoney",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formInline.settlementMoney",
                                    },
                                  })
                                : _vm._e(),
                              _c("span", [_vm._v(" 元")]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "使用说明", prop: "remark" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          rows: "4",
                          resize: "none",
                          type: "textarea",
                          disabled: _vm.isDetail,
                          maxlength: "200",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.formInline.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "remark", $$v)
                          },
                          expression: "formInline.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            margin: "0 auto",
            width: "250px",
            "margin-bottom": "20px",
          },
        },
        [
          !_vm.isDetail
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitData()
                    },
                  },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "title" }, [
      _c("div", { staticClass: "title_icon" }),
      _c("span", [_vm._v("优惠券编辑")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }